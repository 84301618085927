<template>
  <div class="redirect">
  </div>
</template>

<script>
export default {
  name: 'redirect',
  mounted () {
    const userAgent = navigator.userAgent
    if (/Android/i.test(userAgent)) {
      location.href = 'fb://' + this.schema + '/' + this.id
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      location.href = 'fb://' + this.schema + '?id=' + this.id
    } else {
      location.href = 'https://www.facebook.com/' + this.id
    }
  },
  computed: {
    /**
     * @return {String} facebookページの種類
     */
    schema () {
      return this.$route.params.schema
    },
    /**
     * @return {String} facebookのprofileID
     */
    id () {
      return this.$route.params.id
    }
  }
}
</script>
